import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'

const Home = (props) => {
  return (
    <div name="home" className="w-full h-screen bg-[#1F4D56]">
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-white">Hi, my name is</p>
        <h1 className="text 4-xl sm:text-7xl font-bold text-[#ccd6f6]">
          Brendan Quirk
        </h1>
        <h2 className="text 4-xl sm:text-7xl font-bold text-[#E58382]">
          Full Stack Engineer and Instructor.
        </h2>
        <p className="text-white py-4 max-w-[700px]">
          I enjoy crafting unique full stack user experiences.
        </p>
        <div className="flex">
          <button
            onClick={() => props.handlePageChange('work')}
            className="text-white border-2 px-6 py-3 my-2 mr-4 flex items-center hover:bg-[#8892b0] hover:border-[#8892b0]"
          >
            View Work
            <span>
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
          <a
            href="https://docs.google.com/document/d/12t1XHWlQg260aPHu6ZCvlURQGSEMpyp2RTpP4l_P9hc/edit?usp=sharing"
            target="blank"
          >
            <button className="text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-[#8892b0] hover:border-[#8892b0]">
              Download Resume
              <span>
                <HiArrowNarrowRight className="ml-3" />
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
