import Loading from '../assets/loading2.png'

export const data = [
  {
    id: 1,
    name: 'NEW PROJECT COMING SOON',
    image: '',
    github: '',
    live: '',
  },
  {
    id: 2,
    name: 'NEW PROJECT COMING SOON',
    image: '',
    github: '',
    live: '',
  },
  {
    id: 3,
    name: 'NEW PROJECT COMING SOON',
    image: '',
    github: '',
    live: '',
  },
  {
    id: 4,
    name: 'NEW PROJECT COMING SOON',
    image: '',
    github: '',
    live: '',
  },
]
