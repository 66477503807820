import React from 'react'
import { data } from '../data/data.js'

const Work = () => {
  const projects = data

  return (
    <div
      name="work"
      className="w-full md:h-screen text-gray-300 bg-[#1F4D56] pt-[90px]"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-300 border-[#E58382]">
            Work
          </p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {projects.map((project, index) => (
            <div
              key={index}
              style={{ backgroundImage: `url(${project.image})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center text-center items-center mx-auto content-div hover:scale-110 duration-500"
            >
              <div className=" ">
                <span className="text-2xl font bold text-white tracking-wider ">
                  {project.name}
                </span>
                <div className="pt-8 text-center ">
                  <a href={project.github} target="_blank">
                    <button
                      className="text-center rounded-lg px-4 py-3 m-2
                      bg-white text-gray-700 font-bold text-lg hover:bg-[#E58382] hover:text-white"
                    >
                      Code
                    </button>
                  </a>
                  <a href={project.live} target="_blank">
                    <button
                      className="text-center rounded-lg px-4 py-3 m-2
                  bg-white text-gray-700 font-bold text-lg hover:bg-[#E58382] hover:text-white"
                    >
                      Live
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Work
